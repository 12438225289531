import { Trans, useTranslation } from 'react-i18next'

export const Hints = () => {
  const { t } = useTranslation()
  return (
    <>
      <h3 className="h1">{t('hints.notesTitle')}</h3>
      <p>{t('hints.note1')}</p>
      <p>{t('hints.note2')}</p>
      <p>{t('hints.note4')}</p>
      <hr />
      <h3 className="h1">{t('hints.limitationsTitle')}</h3>
      <p>
        <Trans i18nKey="hints.limitationsText">
          SomeGPT weiss (fast) alles, was auf
          <a href={import.meta.env.VITE_MAIN_SITE_LINK} target={'_blank'}>
            SomeURL.ch
          </a>
          publiziert war.
        </Trans>
      </p>
      <p>{t('hints.disclaimerText')}</p>
    </>
  )
}
