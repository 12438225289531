export const Illustration = () => {
  return (
    <div className="header__illustration">
      <div className="illustration">
        <picture>
          {/* this could be certainly improved with some CSS magic, so that it doesn't jump and always fills the whole height, (it always fills 100% width and if it doesn't fit vertically, there's whte space on top and bottom)
                Couldn't manage to fix it with CSS
            */}

          <source
            srcSet={
              'https://chregus.rokka.io/dynamic/resize-width-1680-height-700-mode-fill-upscale-false--crop-width-1680-height-700-anchor-smart/o-o.q-7-af-1/3536af.jpg'
            }
            media={'(max-width: 480px)'}
          />
          <source
            srcSet={
              'https://chregus.rokka.io/dynamic/resize-width-1680-height-500-mode-fill-upscale-false--crop-width-1680-height-500-anchor-smart/o-o.q-7-af-1/3536af.jpg'
            }
            media={'(max-width: 950px)'}
          />
          <img
            src={
              'https://chregus.rokka.io/dynamic/resize-width-1680-height-400-mode-fill--crop-width-1680-height-400-anchor-smart/o-o.q-7-af-1/3536af.jpg'
            }
          />
        </picture>
      </div>
    </div>
  )
}
