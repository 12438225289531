import i18n from 'i18next'
import LanguageDetector, { CustomDetector, DetectorOptions } from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

// if we want to always show one language as default, no matter what the browser says
// if you want to use the browser language, just remove the CustomDetector in the detection options
export const defaultLanguage = 'de'

const myDetector: CustomDetector = {
  name: 'myDetectorsName',

  lookup(options: DetectorOptions) {
    return (
      (options.lookupLocalStorage ? localStorage.getItem(options.lookupLocalStorage) : undefined) || defaultLanguage
    )
  },
}

const languageDetector = new LanguageDetector()
languageDetector.addDetector(myDetector)

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(languageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    detection: { order: ['myDetectorsName'] },
    debug: false,
    fallbackLng: defaultLanguage,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          header: {
            title: `${import.meta.env.VITE_SITE_NAME}`,
            slogan: `I am ${import.meta.env.VITE_SITE_NAME} and I know (almost) everything published on <1>${
              import.meta.env.VITE_MAIN_SITE_SHORT
            }</1>.`,
          },
          from: 'from',

          results: {
            initial: `
             "Hello, I am ${import.meta.env.VITE_SITE_NAME} and I know – almost – everything that is published on ${
               import.meta.env.VITE_MAIN_SITE_SHORT
             }. This includes timetables, pricing information, and current disruptions. Ask me anything.`,
            initialEmpty: ``,
          },
          question: {
            label: 'Your Question',
            placeholder: 'Ask me your question',
            buttonText: 'Send',
            microphoneLabel: 'Ask Question (with Microphone)',
            microphoneListening: 'Microphone: Hold down the button and speak',
          },
          proposition: {
            intro: 'Ask your question below or start with one of these examples:',
            example: [
              [
                'Where do I need to board with the bicycle?',
                'Why do the Cobras make noise?',
                'What kind of ticket do I need for a boat trip?',
                'Do I receive compensation if my mode of transportation is late?',
                'When does the Fondue Tram leave?',
              ],
              [
                'When do I need a night supplement?',
                'I would like to buy a ZVV ticket just for zone 121.',
                'Do I need to buy a ticket for my grandchildren too?',
                'Can I take the 8:59 train with my 9 o’clock day pass?',
                'Can I deposit my annual pass during my vacation?',
              ],

              [
                'How much does a ticket from Technorama to Flurstrasse cost?',
                'How much does a ticket from Quellenstrasse to Uitikon cost?',
              ],
              [
                'How do I get tomorrow at 9 am from Quellenstrasse to Luggwegstrasse 65?',
                'How do I get on Friday at 4 pm from Limmatplatz to Winterthur?',
              ],
              [
                'How much does a subscription from Winterthur to Uster cost?',
                'How much does a subscription from Wädenswil to Dietikon cost?',
                'Is a subscription worth it for traveling twice a week from Effretikon to Zurich and back? Half-fare. 2nd class.',
              ],

              ['Current disruptions in Zurich?', 'I left my bag on line 994.'],
            ],
          },
          answers: {
            oneMoment: 'One moment please...',
            serverConnectionError: 'Connection to the server lost :( Please try again soon.',
            otherModelButton: 'Generate new response',
            wasItUseful: 'Was the answer useful?',
            yes: 'Yes',
            no: 'No',
            giveFeedback: 'Give Feedback',
            readHints: 'Read Hints and Limitations',
            letsTryToImprove: "Let's try to improve the answer!",
            improvePrompt: 'Refine your question and try again',
            thanksForFeedback: 'Thanks for your feedback!',
            unhappy: 'Unhappy with the answer?',
            question: 'Question',
            showRefs: 'Show all References',
            hideRefs: 'Minimize References',
          },
          hints: {
            title: 'Hints and Limitations',
            notesTitle: 'Hints',
            note1:
              'The bot does not remember previously asked questions. You need to enter the question again for refinements.',
            note2:
              'It is better to ask complete questions instead of just keywords. Sometimes, reformulating the question may result in a better answer.',
            note3:
              'Providing the current year (e.g., 2023) or asking more specific questions can yield more up-to-date answers.',
            note4: 'If no answer is received or the answer abruptly ends, it is best to try again.',
            note5:
              "We store your questions and answers with us so that we can improve the service over time. That's why we appreciate it if you give us feedback; it helps us tremendously. [Privacy Policy](/privacy)",
            note6:
              'All information is provided without warranty. Please verify the information on the official website of EWZ.',
            limitationsTitle: 'Limitations',
            limitationsText: `${import.meta.env.VITE_SITE_NAME} knows (almost) everything that was published on <1>${
              import.meta.env.VITE_MAIN_SITE_SHORT
            }</1> on ${import.meta.env.VITE_DATE_EN}.`,
            disclaimerText: 'This app is still in Beta.',
          },
          contact: {
            title: 'Contact and Feedback',
            intro: `
              We continue to work to improve, expand, and regularly update ${import.meta.env.VITE_SITE_NAME}. 
              We therefore appreciate any feedback. Or even spreading the word if you are satisfied with ${
                import.meta.env.VITE_SITE_NAME
              }. `,
            emailLabel: 'Your E-Mail (optional):',
            messageLabel: 'Your message:',
            submitButton: 'Submit',
            feedbackReceived: 'Feedback received, thank you!',
            feedbackReceivedError: 'Error while sending feedback, please try the other means to reach us.',
            emailText: 'Oder senden Sie einsend ann email at chregu@liip.ch',
            moreContact: 'More contact options at',
          },
          footer: {
            credits: 'Powered by <1>Liip</1> — <3>Blog Post about the project</3>',
            disclaimer: `This app is still in Beta.`,
            selectLanguage: 'Select Language',
          },
        },
      },
      de: {
        translation: {
          header: {
            title: `${import.meta.env.VITE_SITE_NAME}`,
            slogan: `Ich bin ${import.meta.env.VITE_SITE_NAME} und ich weiss (fast) alles,
             was auf <1>${import.meta.env.VITE_MAIN_SITE_SHORT}</1> publiziert ist.`,
          },
          from: 'vom',
          question: {
            label: 'Deine Frage',
            placeholder: 'Stelle mir deine Frage',
            buttonText: 'Senden',
            microphoneLabel: 'Frage stellen (mit Mikrofon)',
            microphoneListening:
              'Mikrofon: Halten Sie den Knopf gedrückt und sprechen Sie. Schweizerdeutsch geht auch. Meistens.',
          },
          proposition: {
            intro: 'Stelle deine Frage unten oder starte mit einem dieser Beispiele:',
            example: [
              [
                'Wo muss ich mit dem Velo einsteigen?',
                'Wieso machen die Cobras Lärm?',
                'Was für ein Billet brauch ich für eine Schiffahrt?',
                'Erhalte ich eine Entschädigung, wenn mein Verkehrsmittel verspätet ist?',
                'Wann fährt das Fondue Tram?',
              ],
              [
                'Wann brauche ich einen Nachtzuschlag?',
                `Ich möchte ein ZVV-Ticket nur für die Zone 121 lösen.`,
                'Muss ich für meine Enkel auch ein Billett lösen?',
                'Kann ich mit meinem 9-Uhr-Tagespass den 8:59-Zug nehmen?',
                'Kann ich mein Jahresabonnement  während meiner Ferien hinterlegen?',
              ],

              [
                'Wie viel kostet ein Ticket vom Technorama zur Flurstrasse?',
                'Wie viel kostet ein Ticket von der Quellenstrasse nach Uitikon?',
              ],
              [
                'Wie komme ich morgen um 9 Uhr von der Quellenstrasse zur Luggwegstrasse 65?',
                'Wie komme ich am Freitag um 16 Uhr vom Limmatplatz nach Winterthur?',
              ],
              [
                `Was kostet ein Abo von Winterthur nach Uster?`,
                `Was kostet ein Abo von Wädenswil nach Dietikon?`,
                'Lohnt sich ein Abo für zwei mal pro Woche von Effretikon nach Zürich retour? Halbtax. 2. Klasse.',
              ],

              ['Aktuelle Störungen in Zürich?', 'Ich habe meine Tasche auf der Linie 994 liegengelassen.'],
            ],
          },
          results: {
            initial: `
              Hallo, ich bin ${import.meta.env.VITE_SITE_NAME} und ich weiss – fast – alles, was auf ${
                import.meta.env.VITE_MAIN_SITE_SHORT
              } für Privatkunden publiziert ist.  Frag mich etwas.
`,
            initialEmpty: ``,
          },
          answers: {
            oneMoment: 'Einen Moment bitte...',
            serverConnectionError: 'Verbindung zum Server abgebrochen :( Bitte bald nochmals probieren.',
            otherModelButton: 'Neue Antwort generieren',
            wasItUseful: 'War die Antwort hilfreich?',
            yes: 'Ja',
            no: 'Nein',
            giveFeedback: 'Feedback geben',
            readHints: 'Hinweise und Einschränkungen lesen',
            letsTryToImprove: 'Lass uns versuchen, die Antwort zu verbessern!',
            improvePrompt: 'Frage verfeinern und nochmals probieren',
            thanksForFeedback: 'Danke für Ihr Feedback!',
            unhappy: 'Unzufrieden mit der Antwort?',
            question: 'Frage',
            showRefs: 'Alle Referenzen anzeigen',
            hideRefs: 'Referenzen minimieren',
          },
          hints: {
            title: 'Hinweise und Einschränkungen',
            notesTitle: 'Hinweise',
            note1:
              'ewzGTP ist ein Experiment. Es handelt sich dabei nicht um ein offizielles ewz Produkt, sondern ist viel mehr als Vehikel im Innovationsprozess zu verstehen. Mit ewzGTP und der Zusammenarbeit mit Liip möchten wir durch ein exploratives Vorgehen lernen zu verstehen, wie wir zukünftig mit der Hilfe von KI noch mehr Wert für unsere Kundinnen und Kunden erzielen können. ',

            note2:
              'Es ist besser ganze Fragen zu stellen, anstatt nur Stichwörter. Manchmal hilft es auch eine Frage anders zu formulieren, um eine perfektere Antwort zu erhalten.',
            note3:
              'Oder die Jahreszahl (z.B. 2023) mitzuliefern für aktuellere Antworten. Oder generell spezifischer zu fragen.',
            note4: 'Alle Angaben ohne Gewähr. Bitte überprüfe die Information auf der offiziellen Webseite ewz.ch.',
            note5: '',
            note6: '',
            limitationsTitle: 'Einschränkungen',
            limitationsText: `${import.meta.env.VITE_SITE_NAME} weiss (fast) alles, 
            was am ${import.meta.env.VITE_DATE_DE} auf ewz.ch publiziert war.`,
            disclaimerText: 'Diese Seite ist noch in einer Beta/Test Phase',
          },
          contact: {
            title: 'Kontakt und Feedback',
            intro: `
              Wir arbeiten kontinuierlich daran ${
                import.meta.env.VITE_SITE_NAME
              } zu verbessern, zu erweitern und regelmässig zu aktualisieren.
              Wir freuen uns daher über jedes Feedback. Oder auch einfach weitererzählen, wenn du mit ${
                import.meta.env.VITE_SITE_NAME
              } zufrieden bist. `,
            emailLabel: 'Deine E-Mail (optional):',
            messageLabel: 'Deine Nachricht:',
            submitButton: 'Abschicken',
            feedbackReceived: 'Feedback erhalten, danke!',
            feedbackReceivedError: 'Da ist was schief gelaufen, bitte kontaktiere uns auf anderen Wegen.',
            emailText: 'Oder senden Sie eine Mail an chregu@liip.ch',
            moreContact: 'Mehr Kontaktmöglichkeiten auf',
          },
          footer: {
            credits: 'Powered by <1>Liip</1> — <3>Blog Post über das Projekt</3>',
            disclaimer: `Diese Seite ist noch in einer Beta/Test Phase`,
            selectLanguage: 'Sprache auswählen',
          },
        },
      },
      fr: {
        translation: {
          header: {
            title: `${import.meta.env.VITE_SITE_NAME}`,
            slogan: `Je suis ${
              import.meta.env.VITE_SITE_NAME
            } et je connais (presque) tout ce qui a été publié sur <1>${import.meta.env.VITE_MAIN_SITE_SHORT}</1>.`,
          },
          from: 'du',

          results: {
            initial: `
            Bonjour, je suis ${import.meta.env.VITE_SITE_NAME} et je sais – presque – tout ce qui est publié sur ${
              import.meta.env.VITE_MAIN_SITE_SHORT
            }, zvv.ch et zsg.ch. Cela inclut les horaires, les informations sur les tarifs et les perturbations actuelles. Posez-moi une question.`,
            initialEmpty: ``,
          },
          question: {
            label: 'Votre Question',
            placeholder: 'Posez-moi votre question',
            buttonText: 'Envoyer',
            microphoneLabel: 'Poser la question (avec microphone)',
            microphoneListening: 'Microphone: Maintenez le bouton enfoncé et parlez',
          },
          proposition: {
            intro: 'Posez votre question ci-dessous ou commencez avec un de ces exemples:',
            example: [
              [
                'Où dois-je monter avec le vélo ?',
                'Pourquoi les Cobras font-ils du bruit ?',
                'Quel type de billet ai-je besoin pour un voyage en bateau ?',
                'Est-ce que je reçois une compensation si mon moyen de transport est en retard ?',
                'Quand part le tram Fondue ?',
              ],
              [
                "Quand ai-je besoin d'un supplément nocturne ?",
                'Je voudrais acheter un billet ZVV juste pour la zone 121.',
                'Dois-je aussi acheter un billet pour mes petits-enfants ?',
                'Puis-je prendre le train de 8h59 avec mon pass journalier de 9 heures ?',
                'Puis-je déposer mon abonnement annuel pendant mes vacances ?',
              ],

              [
                'Combien coûte un billet du Technorama à la Flurstrasse ?',
                'Combien coûte un billet de la Quellenstrasse à Uitikon ?',
              ],
              [
                'Comment puis-je me rendre demain à 9 heures de la Quellenstrasse à la Luggwegstrasse 65 ?',
                'Comment puis-je me rendre vendredi à 16 heures du Limmatplatz à Winterthur ?',
              ],
              [
                'Combien coûte un abonnement de Winterthur à Uster ?',
                'Combien coûte un abonnement de Wädenswil à Dietikon ?',
                "Est-ce que ça vaut la peine de prendre un abonnement pour aller deux fois par semaine d'Effretikon à Zurich et retour ? Demi-tarif. 2ème classe.",
              ],

              ["J'ai laissé mon sac sur la ligne 994."],
            ],
          },
          answers: {
            oneMoment: "Un moment s'il vous plaît...",
            serverConnectionError: 'Connexion au serveur interrompue :( Veuillez réessayer bientôt.',
            otherModelButton: 'Générer une nouvelle réponse',
            wasItUseful: 'La réponse vous était utile?',
            yes: 'Oui',
            no: 'Non',
            giveFeedback: 'Donner un feedback',
            readHints: 'Lire les astuces et limitations',
            letsTryToImprove: 'Essayons d’améliorer les choses!',
            improvePrompt: 'Affiner la question et réessayer',
            thanksForFeedback: 'Merci pour votre feedback!',
            unhappy: 'Insatisfait de la réponse?',
            question: 'Question',
            showRefs: 'Afficher toutes les références',
            hideRefs: 'Minimiser les références',
          },
          hints: {
            title: 'Astuces et Limitations',
            notesTitle: 'Astuces',
            note1: `${
              import.meta.env.VITE_SITE_NAME
            } ne se souvient pas des questions posées. Si la réponse ne vous convient pas, reprenez votre question et affinez-la pour améliorer la qualité de la réponse.`,
            note2:
              'Il est préférable de poser des questions complètes plutôt que simplement des mots-clés. Parfois, reformuler la question peut donner une meilleure réponse.',
            note3:
              "Fournir l'année en cours (par exemple, 2023) ou poser des questions plus spécifiques peut donner des réponses plus à jour.",
            note4: "Si aucune réponse n'est reçue ou si la réponse se termine brusquement, relancez votre recherche.",
            note5:
              "Nous stockons vos questions et réponses chez nous afin de pouvoir améliorer le service au fil du temps. C'est pourquoi nous apprécions si vous nous donnez des retours ; cela nous aide énormément. [Politique de Confidentialité](/privacy)",
            note6:
              'Toutes les informations sont fournies sans garantie. Veuillez vérifier les informations sur le site officiel de EWZ.',
            limitationsTitle: 'Limitations',
            limitationsText: `${import.meta.env.VITE_SITE_NAME} connaît uniquement ce qui a été publié sur <1>${
              import.meta.env.VITE_MAIN_SITE_SHORT
            }</1> avant le ${import.meta.env.VITE_DATE_FR}.`,
            disclaimerText: 'Diese Seite ist noch in einer Beta/Test Phase',
          },
          contact: {
            title: 'Contact et Commentaires',
            intro: `
              Nous continuons à travailler dur pour améliorer, agrandir et mettre à jour régulièrement ${
                import.meta.env.VITE_SITE_NAME
              }. 
              Nous apprécions donc tous les retours. Ou même en parler autour de vous si vous êtes satisfait de ${
                import.meta.env.VITE_SITE_NAME
              }. `,
            emailLabel: 'Votre E-Mail :',
            messageLabel: 'Votre message :',
            submitButton: 'Envoyer',
            feedbackReceived: 'Merci pour votre message!',
            feedbackReceivedError: 'Quelque chose s’est mal passé, veuillez nous contacter par d’autres moyens.',
            emailText: 'Ou envoyez un email à chregu@liip.ch',
            moreContact: "Plus d'options de contact sur",
          },
          footer: {
            credits: 'Propulsé par <1>Liip</1> — <3>Article sur le projet</3>',
            disclaimer: `Diese Seite ist noch in einer Beta/Test Phase`,
            selectLanguage: 'Sélectionner la Langue',
          },
        },
      },
    },
  })

export default i18n
