import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CaretBottom } from '../icons/CaretBottom.tsx'
import { Close } from '../icons/Close.tsx'
import { Hint } from '../icons/Hint.tsx'
import { Megaphone } from '../icons/Megaphone.tsx'
import { FeedbackForm } from './FeedbackForm.tsx'
import GlobalContext from './GlobalContext.tsx'
import { Hints } from './Hints.tsx'
import { Tooltip } from './Tooltip.tsx'

export const Header = (props: {
  feedbackRef?: unknown
  inputValue?: string
  requestId?: string | null
  userId?: string | null
  openDrawer?: string | null
  handleMenuItemClick?: (drawerName: string | ((prevState: string | null) => string | null)) => void
}) => {
  const { t, i18n } = useTranslation()
  const { mainLanguage, langs, setMainLanguage } = useContext(GlobalContext)

  const [isMobile, setIsMobile] = useState(window.innerWidth < 640)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      <header className="header">
        <div className="container">
          <div className="header__text">
            <a href="/" className="header__logo" onClick={() => props.handleMenuItemClick?.('')}>
              <img
                src="https://ewz.ch/content/site/ewz/webportal/en/kundenvorteile/jcr:content/logo/image.ewzimg.width_ewznative.png/1663258183501.png"
                alt="ewz"
                style={{ height: '30px' }}
              />
              <h1 style={{ height: 42, fontWeight: '900', fontSize: 30 }}>GPT</h1>
              <h1 className="sr-only">{t('header.title')}</h1>
            </a>
          </div>
          <div className="header__menu">
            <button
              className={`button button--menu ${props.openDrawer === 'feedback' ? 'active' : ''}`}
              onClick={() => props.handleMenuItemClick?.('feedback')}
            >
              <span>{t('contact.title')}</span>
              <Megaphone />
            </button>
            <button
              className={`button button--menu ${props.openDrawer === 'hints' ? 'active' : ''}`}
              onClick={() => props.handleMenuItemClick?.('hints')}
            >
              <span>{t('hints.notesTitle')}</span>
              <Hint />
            </button>
          </div>
          <div className="header__language">
            <label htmlFor="language-switcher" className="sr-only">
              {t('footer.selectLanguage')}
            </label>
            <div className="select-wrapper">
              <select
                id="language-switcher"
                defaultValue={mainLanguage}
                className="language-switcher"
                onChange={e => {
                  setMainLanguage(e.target.value)
                  i18n.changeLanguage(e.target.value)
                }}
              >
                {Object.keys(langs).map(lng => (
                  <option key={lng} value={lng}>
                    {isMobile ? <>{lng}</> : <>{langs[lng].nativeName}</>}
                  </option>
                ))}
              </select>
              <CaretBottom />
            </div>
          </div>
        </div>
        <Tooltip />
      </header>
      <div className={`menu-drawer ${props.openDrawer === 'feedback' ? 'open' : ''}`}>
        <div className="menu-drawer__bg" onClick={() => props.handleMenuItemClick?.('')}></div>
        <div className="menu-drawer__content">
          <div className="container">
            <button className="button menu-drawer__close" onClick={() => props.handleMenuItemClick?.('')}>
              <span>Close</span>
              <Close />
            </button>
            <FeedbackForm inputValue={props.inputValue || ''} requestId={props.requestId} userId={props.userId} />
          </div>
        </div>
      </div>

      <div className={`menu-drawer ${props.openDrawer === 'hints' ? 'open' : ''}`}>
        <div className="menu-drawer__bg" onClick={() => props.handleMenuItemClick?.('')}></div>
        <div className="menu-drawer__content">
          <div className="container">
            <button className="button menu-drawer__close" onClick={() => props.handleMenuItemClick?.('')}>
              <span>Close</span>
              <Close />
            </button>
            <Hints />
          </div>
        </div>
      </div>
    </>
  )
}
